.container {
    padding: 15px 0px;
    padding-left: 18px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: var(--fs-4);
    &__correct {
        background: #abcf43;
    }

    &__incorrect {
        background: #de3d3d;
    }
}

.form-group {
    display: block;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: inherit;
    height: 30px;

}

.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #f04d19;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
}

.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #f04d19;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.answered input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.answered label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid white;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
}

.answered label {
    color: white;
}
