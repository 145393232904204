@use './fonts.scss';

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

dialog {
    border: none;
}

footer,
header,
nav,
section,
main {
    display: block;
}

body {
    overflow-x: hidden;
    background-color: #f9f7f8;
    font-family:
        'SF Pro Display Regular',
        AmaticSC,
        Roboto,
        Segoe UI,
        system-ui,
        -apple-system,
        sans-serif;
    line-height: 1.2;
    font-size: var(--fs-5);
}

body::-webkit-scrollbar {
    width: 0;
}
body {
    -ms-overflow-style: none;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input {

    -webkit-appearance: none;
    border-radius: 0;
}

:root {
    --light-orange: #f4997c;
    --orange: #f04d19;
    --red: #dc3636;
    --yellow: #f4a52d;
    --green: #8fac3c;
    --blue: #3b57b7;
    --light-blue: #5973ca;
    --dark: #33373e;
    --text-dark: #525d6c;
    --text-gray: #adb3b9;
    --fs-1: clamp(1.5rem, 1.1071rem + 1.9643vw, 2.875rem);
    --fs-2: clamp(1rem, 0.4286rem + 2.8571vw, 3rem);
    --fs-3: clamp(1rem, 0.7857rem + 1.0714vw, 1.75rem);
    --fs-4: clamp(0.875rem, 0.6932rem + 0.9091vw, 1.375rem);
    --fs-5: clamp(0.75rem, 0.6786rem + 0.3571vw, 1rem);
    --fs-6: clamp(0.5rem, 0.3571rem + 0.7143vw, 1rem);
    --fs-7: clamp(0.395rem, 0.2979rem + 0.6357vw, 0.85rem);
}

a {
    text-decoration: none;
    color: unset;
}

span {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family:
        'Merriweather Regular',
        Roboto,
        Segoe UI,
        system-ui,
        -apple-system,
        sans-serif;
    color: #525d6c;
    font-weight: 400;
}
address {
    font-style: normal;
}

#bottom-container {
    position: fixed;
    bottom: -1px;
    width: 100%;
    max-width: 1200px;
    transform: translate(-50%);
    left: 50%;
    z-index: 1001;
    display: flex;
    flex-direction: column-reverse;
}

button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

textarea {
    border: none;
    background-color: transparent;
    outline: none;
    &::-webkit-scrollbar {
        color: inherit;
    }
}

pre {
    white-space: pre-line;
}
