.footer {
    &__copyright {
        font-size: var(--fs-6);
        color: #fff;

        &-contact-info {
            margin-top: 10px;
        }

        &-rights {
            margin-top: 10px;
        }
    }
}

@media (max-width: 576px) {
    .footer {
        &__copyright {
           display: none;
        }
    }
}
