.admin-feat-card {
    position: relative;
    color: #525d6c;
    height: 100%;
    user-select: none;

    &__container {
        background-color: #fff;
        padding: 1rem;
        border-radius: 1rem;
        height: 100%;
    }

    &__play-icon {
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 1;
        color: var(--orange);
        height: 1em;
        vertical-align: -0.125em;
        font-size: var(--fs-3);
    }

    &__img-container {
        position: relative;
        min-height: 300px;
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0px;
        margin: auto;
        object-fit: cover;
        border-radius: 1rem;
        filter: grayscale(1);
        transition: 0.1s;
    }

    &__title {
        width: 200px;
        height: 60px;
        margin-top: 10px;
        font-size: var(--fs-5);
        color: #525d6c;
        font-weight: 400;
    }

    &__date {
        margin-top: 10px;
        color: #525d6c;
    }

    &__actions {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
    }

    &__preview {
        cursor: pointer;
        &:hover::before {
            display: none;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            content: 'Предосмотр';
            background-color: #a8b2c0;
            height: 300px;
            width: 100%;
            transition: 0.3s;
            border-radius: 15px;
            color: #fff;
            font-size: var(--fs-4);
            z-index: 10;
        }
    }
}
