.alert {
    width: min(400px, 80vw);
    height: min(160px, 80vw);

    margin-top: 15px;
    padding: 25px;
    color: #fff;
    border-radius: 7px;
    background-color: #ffffff;
    position: relative;

    &__modal {
        z-index: 10001;
    }

    &__title {
        font-size: var(--fs-4);
        margin-bottom: 10px;
        color: var(--orange);
        text-transform: uppercase;
    }

    &__text {
        color: #525d6c;
    }

    &__icon {
        position: absolute;
        color: #525d6c;
        height: 1em;
        right: 10px;
        top: 10px;
        opacity: 0.7;
        cursor: pointer;
    }

    &.active {
        animation: animation 0.5s forwards;
    }
}

@keyframes animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
