.preloader {
    display: block;
    min-height: max(15vh, 400px);
    height: 100%;
    background-image: url('../../assets/logo.webp');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50px;
    border-radius: 1rem;
    box-shadow: 0 0 20px #ccc;
    animation: 1s pulse linear infinite;

}

@keyframes pulse {
    50% {
        opacity: .5
    }
}