.button {
    font-size: var(--fs-5);
    padding: 0.75rem 2rem;
    display: block;
    transition: 0.1s;
    border-radius: 1.4rem;
    border: 1px solid transparent;
    width: fit-content;
    cursor: pointer;
    white-space: nowrap;
    &--orange {
        background-color: var(--orange);
        color: #fff;
        transition: 0.4s;
        &:hover {
            background-color: #fff;
            color: var(--orange);
            border-color: var(--orange);
        }
    }

    &--gray {
        background-color: var(--text-gray);
        color: #fff;
        transition: 0.4s;
        &:hover {
            background-color: #fff;
            color: var(--text-gray);
            border-color: var(--text-gray);
        }
    }

    &--light-orange {
        background-color: var(--light-orange);
        color: #fff;
        transition: 0.4s;
        &:hover {
            background-color: #fff;
            color: var(--light-orange);
            border-color: var(--light-orange);
        }
    }

    &--orange-gradient {
        background: linear-gradient(90deg, #f04d19 6.9%, #f17f5b 87.82%);
        color: #fff;
        transition: 0.4s;
        &:hover {
            color: var(--light-orange);
            border-color: var(--light-orange);
            background: #fff;
        }
    }

    &--primary {
        background-color: #fff;
        border: 1px solid var(--dark);
        border-radius: 1.4rem;
        color: var(--orange);
        transition: 0.4s;
        &:hover {
            background-color: rgb(248, 246, 244);
            color: var(--orange);
            border-color: var(---orange);
        }
    }

    &--transparent {
        background-color: transparent;
        border: 1px solid white !important;
        border-radius: 1.4rem;
        color: white;
       
    }

    &--no-fill-orange {
        width: fit-content;
        padding: 5px 10px;
        border-radius: 15px;
        border: 1px solid var(--orange);
        color: var(--orange);
        cursor: pointer;
        background-color: transparent;
    }

    &--admin {
        background-color: var(--blue);
        color: #fff;
        &:hover {
            background-color: rgb(248, 246, 244);
            color: var(--blue);
            border-color: var(---blue);
        }
    }

    &--loading {
        background-color: #adb3b9;
        animation: pulse 1s infinite;
        pointer-events: none;
    }

    &--disabled {
        background-color: #adb3b9;
        background: #adb3b9;
        pointer-events: none;
        color: white;
    }

    &--white {
        background-color: #fff;
        color: rgba(82, 93, 108, 1);
        font-weight: 600;
    }

    &--light-orange-gradient {
        background: linear-gradient(90deg, #ec9954 6.9%, #f79b50 88.82%);
        color: #fff;
    }
}

@keyframes pulse {
    50% {
        opacity: 0.5;
    }
}

@media (max-width: 960px) {
    .button {
        &--orange {
            &:hover {
                background-color: var(--orange);
                color: #fff;
                border-color: transparent;
            }
        }

        &--gray {
            &:hover {
                background-color: var(--text-gray);
                color: #fff;
                border-color: transparent;
            }
        }

        &--light-orange {
            &:hover {
                background-color: var(--light-orange);
                color: #fff;
                border-color: transparent;
            }
        }

        &--primary {
            &:hover {
                background-color: #fff;
                color: var(--orange);
                border-color: var(--dark);
            }
        }

        &--admin {
            &:hover {
                background-color: var(--blue);
                color: #fff;
                border-color: transparent;
            }
        }
    }
}
