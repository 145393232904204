.admin-contest-preview {
    padding: 20px;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--orange);
    gap: 3rem;
    cursor: pointer;

    &__container {
        height: 100%;
        min-height: 350px;
        border-radius: 1rem;
        overflow: hidden;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__rubrics {
        text-align: center;
        background-color: #fff9;
        width: fit-content;
        padding: 3px 20px;
        margin: auto;
        border-radius: 15px;
        color: #525d6c;
    }

    &__title {
        color: #fff;
        text-shadow: 0px 0px 3px black;
        font-size: var(--fs-4);
    }

    &__result-btn {
        font-size: var(--fs-5);
        padding: 0.75rem 2rem;
        display: block;
        transition: 0.1s;
        border-radius: 1.4rem;
        color: var(--text-dark);
        border: 1px solid transparent;
        background-color: #fff;
    }

    &__time {
        color: #fff;
        text-shadow: 0px 0px 3px black;
    }

    &__actions {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
    }

    &__status {
        display: flex;
        color: #e7dfdf;
        &-icon {
            margin-right: 5px;
            font-size: var(--fs-5);
            height: 1em;
        }
    }
}
