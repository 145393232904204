.footer {
    &__nav-list {
        display: flex;
        justify-content: center;
        gap: 5vw;

        &-link {
            color: #fff;
            transition: 0.4s;
            &:hover {
                color: var(--light-orange);
            }
        }
    }
}

@media (max-width: 960px) {
    .footer {
        &__nav-list {
            &-link {
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}


@media (max-width: 576px) {
    .footer {
        &__nav-list {
            display: none;
        }
    }
}

