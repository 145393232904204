.feat-card {
    position: relative;
    cursor: pointer;
    color: #525d6c;
    height: 100%;
    user-select: none;
    padding-bottom: 45px;
    &__container {
        background-color: #fff;
        padding: 24px 26px;
        border-radius: 1rem;
        height: 100%;
        &-header {
            display: flex;
        }
    }

    &__play-icon {
        color: var(--orange);
        height: 40px;
        min-width: 40px;
        vertical-align: -0.125em;
        font-size: var(--fs-3);
        transition: 0.4s;

        &:hover {
            animation-duration: 1s;
            animation-name: scale-btn;
        }
    }

    &__img-container {
        position: relative;
        min-height: 300px;
        &-header {
            z-index: 10;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
        }
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0px;
        margin: auto;
        object-fit: cover;
        border-radius: 1rem;
        filter: grayscale(1);
        transition: 0.1s;

        &:hover {
            filter: grayscale(0);
        }
    }

    &__preview-detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 28px;
    }

    &__title {
        font-size: var(--fs-3);
        height: 40%;
        font-family: 'Gilroy-Medium';
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 80px;
    }

    &__footer {
        width: 100%;
        bottom: 5px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__view-stat {
        display: inline-flex;
        align-items: center;
        -webkit-user-select: none;
        user-select: none;
        gap: 5px;
        color: #adb3b9;
    }

    &__view-icon {
        height: 30px;

        vertical-align: -0.125em;
        color: #525d6c;
    }

    &__rubrics {
        width: calc(100% - 40px - 20px);
        display: flex;
        align-items: center;
        gap: 10px;
        &-item {
            padding: 6px 12px;
            color: #adb3b9;
            border: 1px solid #adb3b9;
            border-radius: 20px;
            max-width: 50%;
            &-value {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
            }
        }
    }
}

@media (max-width: 576px) {
    .feat-card__container {
        padding: 12px 14px;
    }
    .feat-card__title {
        height: 40px;
    }
}

@keyframes scale-btn {
    20% {
        transform: translateY(1.1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
