.audioplayer {
    &__progress-container {
        width: 100%;
        position: relative;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__progress {
        position: relative;
        margin: 0;
        height: 100%;
        width: 100%;
        background-color: rgb(223, 222, 222);
    }
}

input[type='range'] {
    --range-progress: 0;
    -webkit-appearance: none;
    position: relative;
    background: #ccc;
    cursor: pointer;
}

input[type='range']::-moz-range-track {
    position: relative;
    background: #ccc;
    width: 100%;
    cursor: pointer;
}

input[type='range']::before {
    content: '';
    height: 100%;
    background: var(--orange);
    width: var(--range-progress);

    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
}

input[type='range']::-moz-range-progress {
    background: #f50;
    height: 10px;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 10px;
    width: 0px;
    border: none;
    background-color: #f50;
    cursor: pointer;
    position: relative;
}

input[type='range']::-moz-range-thumb {
    height: 10px;
    width: 0px;
    background: #f50;
    cursor: pointer;
    border: transparent;
    position: relative;
}
