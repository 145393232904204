.avatar_detail {
    height: 60px;
    display: flex;
    align-items: center;
    gap: 10px;
    &_date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        width: calc(100% - 42px - 10px - 4px);
    }
    &_avatar_not_found {
        min-width: 42px;
        min-height: 42px;
        background-color: #858585;
        border-radius: 50%;
    }
    &_avatar {
        height: 42px;
        width: 42px;
        border-radius: 50%;
        object-fit: cover;
    }
    &_author {
        color: #525d6c;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    }
    &_publish_date {
        height: 24px;
        color: #adb3b9;
        color: #adb3b9;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (max-width: 386px) {
    .avatar_detail_author {
        width: 180px;
    }
}