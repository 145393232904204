.contest-modal {
    max-width: min(1000px, 95vw);
    min-height: 80vh;
    padding: 2rem;

    

    &__text {
        margin-top: 15px;
        font-size: var(--fs-5);
        color: rgba(151, 151, 151, 1);
    }

    &__title {
        font-size: clamp(1rem, 0.3286rem + 2.4571vw, 2.4rem);
        text-transform: uppercase;
        font-weight: 700;
    }

    &__actions {
        margin-top: 35px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    &__media-examples {
        &-title {
            margin-top: 40px;
            color: rgba(151, 151, 151, 1);
        }
        &-list {
            margin-top: 30px;

            display: grid;
            gap: 1.5rem;
            grid-template-columns: repeat(3, minmax(250px, 400px));
        }
    }

    &__faq {
        margin-top: 50px;

        &-title {
            color: rgba(151, 151, 151, 1);
            font-size: clamp(0.75rem, 0.6786rem + 0.3871vw, 1.4rem);

            &-container {
                margin-top: 10px;
                margin-bottom: 20px;

                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        &-tab {
            border-bottom: 1px solid rgb(192 195 199);

            &-icon {
                height: 2.5em;

                cursor: pointer;
                color: rgba(236, 238, 242, 1);
                &--active {
                    color: rgba(212, 212, 212, 1);
                }
            }

            &-title {
                font-size: clamp(0.75rem, 0.6786rem + 0.3871vw, 1.4rem);
                max-width: 75%;
            }

            &-text {
                transition: 0.4s;

                margin-bottom: 10px;
                color: rgba(151, 151, 151, 1);
                max-height: 0;
                overflow: hidden;

                &--active {
                    max-height: 100px;
                }
            }
        }
    }

    &__social-media {
        margin-top: 40px;

        &-title {
            text-transform: uppercase;
            color: rgba(82, 93, 108, 1);
            font-size: var(--fs-3);
        }

        &-list {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            margin-top: 15px;
            &-item {
                display: flex;
                gap: 5px;
                align-items: center;
                &-icon {
                    height: 1.5em;
                    color: rgba(217, 217, 217, 1);
                }
            }
        }
    }

    &__contest-work-modal {
        overflow-y: visible;
    }

    &__cup-img {
        position: absolute;
        right: -6%;
        top: -5%;
        width: min(130px, 18vw);
        z-index: 1;
    }

    &__medal-img {
        position: absolute;
        left: -15%;
        bottom: -2%;
        width: min(80px, 12vw);
    }

    &__contest-form {
    }
}

@media screen and (max-width: 900px) {
    .contest-modal {
        &__media-examples {
            &-list {
                grid-template-columns: repeat(2, minmax(200px, 450px));
            }
        }
    }
}

@media screen and (max-width: 570px) {
    .contest-modal {
        &__media-examples {
            &-list {
                grid-template-columns: 1fr;
            }
        }

        &__medal-img {
            left: -9.5%;
            width: min(80px, 12vw);
        }
    }
}
