.audioplayer {
    &__controls-container {
        display: flex;
        gap: 5px;
    }

    &__controls {
        display: flex;
        gap: 5px;
    }

    &__svg {
        color: var(--text-dark);
        font-size: 1.2rem;
        cursor: pointer;
        height: 1em;
    }
}
