.social_list {
    display: flex;
    gap: 16px;
    align-items: center;
}
.icon {
    cursor: pointer;
    &:hover rect {
        fill: var(--orange);
        transition: 0.3s;
    }
}

@media (max-width: 576px) {
    .icon {
        width: 20px;
        height: 20px;
    }

    .social_list {
        gap: 8px;
    }
}
