.clouds-bg {
    position: fixed;
    z-index: -999;
    height: 100vh;
    width: 100vw;

    &__top {
        top: 5vh;
        right: -150px;
        max-width: 300px;
        position: absolute;
        width: 100%;

    }

    &__bottom {
        bottom: 6vh;
        left: -250px;
        max-width: 400px;
        position: absolute;
        width: 100%;
    }
}
