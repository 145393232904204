.profile-header {
    &__nav {
        display: flex;
        justify-content: space-between;
        list-style-type: none;

        &-list {
            display: flex;
            justify-content: center;
            gap: 5vw;

            &-item {
                color: #525d6c;
                transition: 0.4s;
                &:hover {
                    color: var(--light-orange);
                }
            }
        }
        &-link--active {
            color: var(--orange);
        }

        &-link--active-admin-panel {
            color: var(--blue);
        }

        &-text-link {
            display: block;
        }

        &-icon {
            display: none;
            font-size: 1.4rem;
            color: inherit;
            height: 1em;
            vertical-align: -0.125em;
            width: 1em;
        }
    }
}

@media (max-width: 980px) {
    .profile-header {
        &__nav {
            &-list {
                &-item {
                    &:hover {
                        color: #525d6c;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .profile-header {
        &__nav {
            &-text-link {
                display: none;
            }

            &-icon {
                display: block;
            }
        }
    }
}
