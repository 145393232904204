.signin {
    &__form {
        width: min(300px, 80vw);
        margin: auto;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-title {
            font-size: var(--fs-3);
            margin-bottom: var(--fs-3);
        }

        &-input-pass {
            margin-top: var(--fs-4);
        }

        &-btn {
            width: 100%;
        }

        &-btn {
            margin-top: var(--fs-3);
        }

        &-vk-btn {
            margin: 5px auto auto;
            cursor: pointer;
        }

        &-input-title {
            margin-bottom: 5px;
        }
    }

    &__icon {
        font-size: 1.2rem;
        height: 1em;
        vertical-align: -0.125em;
        color: #525d6c;

        cursor: pointer;
    }
}
