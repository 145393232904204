.user-page-tabs {
    &__container {
        position: relative;
        padding-bottom: 40px;
    }

    &__list {
        position: absolute;
        top: -50px;
        height: 50px;
        display: flex;
        align-items: flex-end;
        width: 100%;
        background-color: transparent;
        padding: 0 55px;
        &-item-container {
            width: max(185px, 30%);
        }
        &-item {
            background: #f29b80;
            text-align: center;
            color: rgb(255, 255, 255);
            padding: 8px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            cursor: pointer;
            transition: 0.4s;
            font-size: var(--fs-6);
            &--active {
                background-color: rgb(255, 255, 255);
                color: var(--dark);
            }

            &--desktop-visible {
                visibility: visible;
            }
        }
    }

    &__content {
        display: none;
        position: relative;
        border-radius: 20px;
        border-top-left-radius: 0px;
        background-color: rgb(255, 255, 255);

        overflow: hidden;
        padding: 0 55px;

        &--active {
            display: flex;
        }
    }
}

@media (max-width: 678px) {
    .user-page-tabs {
        &__list {
            &-item-container {
                width: min(145px, 30%);
            }
        }
    }

    .user-page-tabs__content {
        padding: 0 20px;
    }

    .user-page-tabs__list {
        padding: 0 20px;
    }
}

@media (max-width: 468px) {
    .user-page-tabs__content {
        padding: 0 30px;
    }

    .user-page-tabs__list {
        padding: 0 35px;
    }
}
