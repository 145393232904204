.text-area {
    display: block;
    font-size: var(--fs-5);
    border-radius: 0.6rem;
    border: none;
    outline: 1px solid #adb3b9;
    accent-color: var(--orange);
    width: 100%;
    min-height: 35px;
    resize: none;
    height: auto;
    padding-right: 10px;

    &__container {
        position: relative;
    }

    &__title {
        font-size: var(--fs-5);
        font-weight: 600;
        color: #525d6c;
    }

    &__icon {
        position: absolute;
        top: 32px;
        right: 8px;
        font-size: var(--fs-5);
        height: 1em;
        color: #525d6c;
        cursor: pointer;
    }

    &__textarea {
        width: max(400px, 20vw);
        height: 400px;
        &-modal {
            z-index: 10010;
        }
    }

    &__error-text {
        font-size: var(--fs-7);
        color: var(--red);
    }
}
