.header {
    &__advertisement {
        display: block;
        background: linear-gradient(106.62deg, #f05a19 17.59%, #feac4c 74.02%);
        &-info {
            background-image: url('../../assets/gamePrizeBanner.png');

            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            &-container {
                padding: 20px;
                height: 120px;
                max-width: 1210px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
            }
        }

        &-title {
            font-size: var(--fs-3);
            text-transform: uppercase;
            color: #ffffff;
            font-family: 'Gilroy-Medium';
            font-weight: 600;
            margin-bottom: 5px;

            &-list {
                max-width: 100%;
            }
        }
        &-subtitle {
            font-size: var(--fs-5);
            color: #ffffff;
        }
    }
}

.btn {
    height: 48px;
    border-radius: 40px;
    color: var(--orange);
    background: #ffffff;
    padding: 14px 0;
    width: min(200px, 30vw);
    font-weight: 700;
}

@media screen and (max-width: 600px) {
    .header {
        &__advertisement {
            &-title {
                font-size: 16px;
                text-transform: uppercase;
                color: #ffffff;
            }
            &-info {
                flex-direction: column;
            }
        }
    }

    .btn {
        height: 40px;
        width: min(200px, 30vw);
    }

    .header__advertisement {
        background: transparent;
    }

    .header__advertisement-info-container {
        height: auto;
        padding-left: 10px;
    }
}

@media screen and (max-width: 497px) {
    .header__advertisement-info {
        background-image: url('../../assets/gamePrizeBannerMobile375.png');
        background-size: cover;
        background-position: 100% 100%;
        width: 100%;
    }
    .header__advertisement-info-container {
        height: 92px;
    }

    .header__advertisement-title {
        font-size: 15px;
        max-width: 240px;
    }

    .header__advertisement-subtitle {
        font-size: 10px;
        max-width: 160px;
    }

    .btn {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .header__advertisement-info-container {
        height: 90px;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
    }
}
