@font-face {
    font-family: 'Merriweather Regular';
    src:
        url('./fonts/merriweather/woff2/Merriweather-Regular.woff2') format('woff2'),
        url('./fonts/merriweather/woff/Merriweather-Regular.woff') format('woff'),
        url('./fonts/merriweather/ttf/Merriweather-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'SF Pro Display Regular';
    src:
        url('./fonts/san-francisco-pro-display/woff2/SF-Pro-Display-Regular.woff2') format('woff2'),
        url('./fonts/san-francisco-pro-display/woff/SF-Pro-Display-Regular.woff') format('woff'),
        url('./fonts/san-francisco-pro-display/ttf/SF-Pro-Display-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Gilroy-Bold';
    src:
        local('Gilroy-Bold'),
        url(./fonts/gilroy/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Gilroy-Medium';
    src:
        local('Gilroy-Medium'),
        url(./fonts/gilroy/Gilroy-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Gilroy-Light';
    src:
        local('Gilroy-Light'),
        url(./fonts/gilroy/Gilroy-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'GoshaSans-Medium';
    src:
        local('GoshaSans-Medium'),
        url(./fonts/gosha/GoshaSans-Medium.woff) format('woff'),
        url(./fonts/gosha/GoshaSans-Medium.woff2) format('woff2');
}

@font-face {
    font-family: 'GoshaSans-Regular';
    src:
        local('GoshaSans-Regular'),
        url(./fonts/gosha/GoshaSans-Regular.ttf) format('ttf'),
        url(./fonts/gosha/GoshaSans-Regular.woff) format('woff'),
        url(./fonts/gosha/GoshaSans-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Anek Gujarati';
    src:
        local('Anek Gujarati'),
        url(./fonts/anek/AnekGujarati_Expanded-Medium.ttf) format('truetype');
}
