.searchbar {
    position: relative;
    width: 100%;

    &__list {
        width: 102%;
        max-height: 300px;
        position: absolute;
        left: -1%;
        top: 55px;
        border-radius: 0.5rem;
        z-index: 10001;
        overflow-y: auto;
        box-shadow:
            0 10px 22px rgba(0, 0, 0, 0.15),
            0 6px 6px rgba(0, 0, 0, 0.1);

        background-color: #fff;

        &-item {
            padding: 5px;
            font-size: var(--fs-5);
            color: #525d6c;
            cursor: pointer;

            &:hover {
                background-color: #eaeef0;
            }
        }

        &--closed {
            visibility: hidden;
        }
    }

    &__input {
        font-size: var(--fs-5);
        padding: 0.5rem 0.7rem;
        border-radius: 0.6rem;
        border: none;
        outline: 1px solid #adb3b9;
        accent-color: var(--orange);
        width: 100%;

        &--invalid {
            outline-color: red;
        }

        &--valid {
            outline-color: #7ed968;
        }

        &__title {
            font-size: var(--fs-5);
            font-weight: 600;
            color: #525d6c;
        }

        &__error-text {
            font-size: var(--fs-7);
            color: var(--red);
        }
    }
}
