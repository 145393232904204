.auth-router {
    &__modal {
        z-index: 10000;
    }

    &__nav-list {
        margin-top: 15px;
        &-item {
            text-align: center; 
            color: #525d6c;
            cursor: pointer;
        }
    }
}
