.mobile-nav {
    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: var(--text-gray);

        &--active {
            color: var(--orange);
        }

        &-text {
            color: inherit;
        }
    }
}
