.text-alert {
    &__container {
        position: fixed;
        z-index: 10100;
        right: 10px;
        bottom: 10px;
    }
}

@keyframes animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
