.button-skeleton {
    display: inline-block;
    width: 10ch;
    height: 1.8rem;
    animation: pulse 1s infinite;
    padding: .2rem .6rem;
    border-radius: 15px;
    background-color: #adb3b9;
}

@keyframes pulse {
    50% {
        opacity: .5
    }
}