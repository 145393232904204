.feat-story {
    padding: 2vw;
    color: #525d6c;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    &__title {
        margin-bottom: 10px;
        font-size: var(--fs-1);
        font-family: 'Gilroy-Medium';
        font-weight: 600;
    }

    &__content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__audio {
        &-btn {
            padding: 0px 25px !important;
            margin: 20px 0;
        }
        &-container {
            display: flex;
            align-items: center;
            gap: 5px;
            width: fit-content;
            cursor: pointer;

            &_icon {
                width: 32px;
            }
        }
    }

    &__play-icon {
        font-size: 1.2rem;
        height: 1em;
        vertical-align: -0.125em;
        color: inherit;
        &:hover {
            color: var(--light-orange);
        }
    }

    &__text {
        white-space: break-spaces;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__img-container {
        position: relative;
        height: 100%;
    }

    &__img {
        width: 100%;
        height: 100%;
        height: auto;
    }

    &__rubrics {
        width: calc(100% - 40px - 20px);
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 14px;
        &-item {
            padding: 6px 12px;
            color: #adb3b9;
            border: 1px solid #adb3b9;
            border-radius: 20px;
            max-width: 50%;
            &-value {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
            }
        }
    }

    &__footer {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__view-stat {
        display: inline-flex;
        align-items: center;
        -webkit-user-select: none;
        user-select: none;
        gap: 5px;
        color: #adb3b9;
    }

    &__view-icon {
        height: 30px;

        vertical-align: -0.125em;
        color: #525d6c;
    }
}

@media screen and (min-width: 700px) {
    .feat-story {
        &__text {
            max-height: 20lh;
            overflow-y: auto;
        }
    }
}
@media screen and (max-width: 700px) {
    .feat-story {
        &__content-container {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 300px;
        }
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
