.feat-example {
    &__container {
        position: relative;
        padding: 1rem 1rem 2rem 1rem;
        max-width: 355px;
        max-height: 680px;
        box-shadow: 0px 0px 22.04px 0px rgba(86, 99, 133, 0.15);
        background-color: rgb(255, 255, 255);
        border-radius: 15px;
    }

    &__img {
        border-radius: 1rem;
        height: 100%;
        inset: 0;
        margin: auto;
        object-fit: contain;
        position: absolute;
        transition: 0.1s;
        width: 100%;
        &-container {
            height: 300px;
            position: relative;
        }
    }

    &__title {
        font-size: var(--fs-4);
        font-weight: 700;
    }

    &__icon {
        width: 2.2em;
        height: 2.2em;
        background-color: rgba(133, 133, 133, 1);
        color: rgb(255, 255, 255);
        border-radius: 50%;
        border: 2px solid rgba(133, 133, 133, 1);
    }

    &__detail {
        &-container {
            margin-top: 15px;
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    &__hero {
        font-size: var(--fs-7);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 200px;
    }

    &__date {
        font-size: var(--fs-7);
        color: var(--text-gray);
    }

    &__description {
        margin-top: 15px;
        max-height: 195px;
        display: -webkit-box;
        -webkit-line-clamp: 10; /* Количество строк */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: gba(82, 93, 108, 1);
        font-size: var(--fs-7);
    }

    &__play-icon {
        position: absolute;
        color: var(--orange);
        height: var(--fs-3);
        z-index: 10;
        right: 10px;
    }
}
