.mobile-nav {
    padding: 0.6rem;
    background-color: #fff;

    &__container {
        display: none;
    }

   
}

@media screen and (max-width: 900px) {
    .mobile-nav {
        &__container {
            display: block;
        }
    }
}
