.logo-img {
    width: 25px;
    &:hover {
        animation: 1s pulse linear;
    }
}

@keyframes pulse {
    50% {
        opacity: 0.5;
    }
}
