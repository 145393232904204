.admin-page {
    &__nav-container {
        background: linear-gradient(
            90deg,
            rgba(249, 247, 248, 1) 0%,
            rgba(25, 76, 240, 1) 0%,
            rgba(204, 217, 231, 1) 100%
        );
    }

    &__nav {
        padding: 10px 20px;

        max-width: 1210px;
        margin: auto;

        &-list {
            display: flex;
            justify-content: start;
            gap: 5vw;

            &-item {
                color: #d0d4d8;
                text-shadow: 1px 1px 1px #838181;
                transition: 0.4s;
                &:hover {
                    color: #fff;
                }
            }
        }
        &-link--active {
            transition: 0.3s;
            color: #fff;
        }
    }
}

@media (max-width: 980px) {
    .admin-page {
        &__nav {
            &-list {
                &-item {
                    &:hover {
                        color: #d0d4d8;
                    }
                }
            }
        }
    }
}
