.profile {

    display: flex;
    align-items: center;
    color: #525d6c;

    &__icon {
        font-size: 1.4rem;
        color: inherit;
        height: 1em;
        vertical-align: -0.125em;
        opacity: 0.7;
    }

    &__name {
        margin-right: 10px;
    }
}
