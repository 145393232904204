.modal {
    z-index: 10010;
}

.banner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &__modal {
        z-index: 10;
        width: min(806px, 80vw);
        border-radius: 15px;
        padding: 3vw;
        overflow-y: visible;
        background-color: transparent;
        background: url('./assets/bg.png') no-repeat;
        background-size: cover;
        background-position: 100% 100%;
        height: 500px;
    }

    &__easel {
        position: absolute;
        right: -180px;
        bottom: 0px;
        z-index: 1000;
    }
    &__palette {
        position: absolute;
        right: 30px;
        bottom: 0px;
        z-index: 1010;
        width: 280px;
    }
    &__mobileeasel {
        display: none;
    }
    &__title {
        position: relative;
        font-family: 'SF Pro Display Regular';
        color: #ffffff;
        font-size: var((--fs-3));
        text-transform: uppercase;
        max-width: 100%;
        line-height: 36px;
        font-weight: 700;
        margin-bottom: 15px;

        span {
            display: inline;
        }
        &__detail {
            border-radius: 70px;
            background-color: #ffffff;
            color: #525d6c;
            padding: 2.5px 8px;
            text-wrap: nowrap;
        }
    }

    &__sub__title {
        margin-top: 15px;
        font-family: 'SF Pro Display Regular';
        color: #ffffff;
        font-size: clamp(0.75rem, 0.6786rem + 0.3571vw, 1.2rem);
        max-width: 55%;
    }

    &__description {
        position: relative;
        color: #ffffff;
        max-width: 55%;
        font-size: var((--fs-5));

        strong {
            white-space: nowrap;
        }
    }

    &__contest_details {
        margin-top: 30px;
        background: #ffffff;
        border-radius: 12px;
        padding: 16px 18px;
        max-width: 65%;

        &_title {
            color: #000000;
            font-size: 20px;
            font-family: 'SF Pro Display Regular';
            font-weight: 500;
        }

        &_subtitle {
            text-transform: uppercase;
            color: #f04d19;
            font-size: var((--fs-4));
            font-family: 'SF Pro Display Regular';
            font-weight: 700;
        }
    }
}

.btn {
    margin-top: 20px;
    height: 48px;
    border-radius: 40px;
    color: var(--orange);
    background: #ffffff;
    padding: 14px 0;
    width: min(200px, 30vw);
    font-weight: 700;
}

@media (max-width: 1100px) {
    .banner {
        &__modal {
            height: 440px;
        }
        &__contest_details {
            margin-top: 20px;

            padding: 10px 12px;
        }

        &__contest_details {
            margin-top: 16px;
            padding: 8px 10px;
        }
    }
}
@media (max-width: 988px) {
    .banner__contest_details_title {
        font-size: var((--fs-4));
    }
}

@media (max-width: 768px) {
    .banner {
        &__easel {
            right: -140px;
            height: 400px;
        }
        &__palette {
            right: 30px;
            width: 220px;
        }
    }
}

@media (max-width: 678px) {
    .text_hide {
        display: none !important;
    }
    .btn {
        width: 100%;
        position: relative;
        z-index: 1010;
    }
    .banner {
        padding: 22px;
        &__modal {
            background-image: url('./assets/bg_mobile.png');
        }

        &__title {
            line-height: 28px;

            &__detail {
                display: block !important;
                width: fit-content;
                padding: 13px;
                font-size: 16px;
                margin-top: 8px;
            }
        }
        &__description {
            max-width: 70%;
        }

        &__contest_details {
            max-width: 200px;
            position: absolute;
            bottom: 80px;
        }

        &__easel {
            display: none;
        }
        &__palette {
            display: none;
        }
        &__mobileeasel {
            display: block;
            position: absolute;
            right: -60px;
            bottom: 0;
            height: 380px;
        }
    }
}

@media (max-width: 578px) {
    .banner {
        &__modal {
            height: 410px;
        }
        padding: 10px;
        &__easel {
            height: 240px;
            right: -70px;
            top: 80px;
        }
        &__title {
            line-height: 24px;
            margin-bottom: 10px;
        }

        &__description {
            max-width: 80%;
        }

        &__contest_details {
            padding: 13px 16px;
            max-width: 160px;
            bottom: 70px;
            &_title {
                color: #525d6c;
                font-size: 12px;
            }
        }

        &__mobileeasel {
            right: -80px;
            height: auto;
        }
    }
}

@media (max-width: 428px) {
    .banner {
        &__mobileeasel {
            right: -90px;
            height: auto;
            width: 220px;
        }
    }
}

@media (max-width: 388px) {
    .banner {
        padding: 6px;

        &__title {
            font-size: 13px;
            margin-bottom: 5px;
        }

        &__contest_details {
        }
    }
}

@media (max-width: 320px) {
    .banner {
        padding: 4px;

        &__title {
            font-size: 13px;
            margin-bottom: 5px;
        }

        &__contest_details {
            padding: 5px 7px;
        }
    }
}
