.page-preloader {
    &--primary {
        min-height: 85vh;
        border-radius: 0%;
        border: none;
        filter: brightness(0) saturate(100%) invert(44%) sepia(75%) saturate(4913%)
            hue-rotate(354deg) brightness(98%) contrast(92%);
        box-shadow: none;
    }

    &--admin {
        min-height: 90vh;
        border-radius: 0%;
        border: none;
        filter: brightness(0) saturate(100%) invert(16%) sepia(62%) saturate(5890%)
            hue-rotate(240deg) brightness(78%) contrast(95%);
        box-shadow: none;
    }
}
