.vk-auth-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 5px auto auto;
    text-align: center;
    background: linear-gradient(90deg, #f04d19 6.9%, #f17f5b 87.82%);
    color: rgb(248, 246, 244);
    font-size: var(--fs-5);
    padding: 0.75rem 2rem;
    transition: 0.1s;
    border-radius: 1.4rem;
    transition: 0.4s;
    margin-bottom: 5px;

    &:hover {
        border-color: var(---orange);
    }

    &__logo {
        color: var(--orange);
        height: 1.1rem;
    }
}

@media (max-width: 960px) {
    .vk-auth-link {
        &:hover {
            background-color: #fff;
            color: var(--orange);
            border-color: var(--dark);
        }
    }
}
