.audioplayer {
    &__controls-container {
        display: flex;
        gap: 20px;
    }

    &__controls {
        display: flex;
        gap: 10px;
    }

    &__svg {
        color: var(--text-dark);
        font-size: 1.2rem;
        cursor: pointer;
        height: 1em;
        transition: 0.4s;
        &:hover {
            color: var(--orange);
        }
    }

    &__playlist-container {
        position: relative;
    }

    &__playlist {
        top: -200px;
        left: -180px;
    }
}
