.audioplayer {
    position: relative;
    background-color: #fff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0.1px 1px 3px 0.5px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 25px 10px;
        gap: 5px;
    }

    &__audio-info {
        overflow: hidden;
    }

    &__audio-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #525d6c;
        font-weight: 400;
    }

    &__audio-hero {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #525d6c;
        font-weight: 400;
    }

    &__svg {
        color: var(--text-dark);
        font-size: 1.2rem;
        cursor: pointer;
        height: 1em;
    }

    &__playlist-container {
        position: relative;
    }

    &__preloader {
        position: absolute;
        width: 100%;
        height: 100%;
        animation: pulse 1s infinite;
        top: 0;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        background-color: #adb3b969;
        pointer-events: none;padding: 10px;
    }
}

@keyframes pulse {
    50% {
        opacity: 0.5;
    }
}
