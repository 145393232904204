.rule_card {
    position: relative;
    padding: 30px;
    border-radius: 12px;
    color: white;

    &__orange {
        background: linear-gradient(90deg, #f04d19 6.9%, #f17f5b 87.82%);
    }
    &__yellow {
        background: linear-gradient(286.89deg, #ffbe68 -18.31%, #f3a03f 107.3%);
    }
    &__red {
        background: linear-gradient(106.17deg, #dc3636 -22.21%, #ff506b 119.78%);
    }
    &__gray {
        background: #eceef2;
    }
}

.triangle {
    position: absolute;
    width: 0px;
    height: 0px;
    background-color: white;
    border-style: solid;
    border-width: 40px 40px 0px 0px;
    transform: rotate(0deg);
    bottom: 0;
    right: 0;

    &__orange {
        border-color: #f04d19 transparent transparent
            transparent;
    }

    &__gray {
        border-color: #eceef2 transparent transparent transparent;
    }
}
