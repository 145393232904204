.modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
    z-index: 1000;

    &.active {
        opacity: 1;
        pointer-events: all;
        animation: animation 0.5s forwards;
    }

    &__content {
        padding: 0.5rem;
        width: fit-content;
        max-height: 70vh;
        max-width: min(1000px, 95vw);
        position: relative;
        border-radius: 1rem;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        overflow-y: auto;
        scrollbar-width: none;

        &-container {
            position: relative;
        }
    }

    &__content::-webkit-scrollbar {
        width: 0;
    }
}

.overflow-hidden {
    overflow: hidden;
}
@keyframes animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
