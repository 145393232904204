.share-btn {
    width: fit-content;
    padding: 3px 15px;
    border: 1px solid var(--orange);

    &__icon {
        cursor: pointer;
        font-size: 20px;
        height: 1em;
        color: #525d6c;
        transition: 0.4s;

        &:hover {
            color: var(--orange);
        }
    }

    &__icons-list {
        transform: translateY(0);
        visibility: hidden;
        transition: 0.3s;
        opacity: 0;
        position: absolute;
        display: flex;
        align-items: center;
        gap: 5px;
        max-width: 100%;
        width: fit-content;
        border: 1px solid var(--dark);
        border-radius: 10px;
        padding: 2px 4px;
        background-color: #fff;
    }

    &__container {
        position: relative;

        &:hover .share-btn__icons-list {
            visibility: visible;
            opacity: 1;
            transform: translateY(-190%);
        }
    }

    &__arrow-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background: linear-gradient(90deg, #f04d19 6.9%, #f17f5b 87.82%);
        padding: 6px 14px;
        border-radius: 20px;
        font-size: 18px;
        color: white;
        font-size: var(--fs-5);
    }
}

@media (max-width: 960px) {
    .share-btn {
        &__icon {
            &:hover {
                color: #525d6c;
            }
        }
    }
}
