.footer {
    width: 100%;
    background-color: var(--dark);

    &__container {
        max-width: 1210px;
        padding: 40px 10px 70px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 8vw;
        row-gap: 25px;
    }
}

@media (max-width: 576px) {
    .footer {
        width: 100%;
        background-color: var(--dark);

        &__container {
            padding: 40px 10px 70px;
            padding-bottom: 80px;
            row-gap: 10px;
        }
    }
}