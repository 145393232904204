.gallery-item-contest-form {
    position: relative;
    width: min(400px, 80vw);
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__input {
        position: relative;
        margin-bottom: 15px;

        &-title {
            margin-bottom: 5px;
        }
    }

    &-title {
        font-size: var(--fs-3);
        margin-bottom: 20px;
    }

    &__create-btn {
        margin-top: 15px;
    }
}
.checkbox {
    margin-top: 14px;
    padding: 0 !important;
    font-size: 12px !important;
}

.link {
    font-weight: 700;
    color: var(--orange);
}
