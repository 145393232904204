.signup {
    width: min(300px, 80vw);
    display: block;
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__form {
        &-title {
            font-size: var(--fs-3);
            margin-bottom: var(--fs-3);
        }

        &-input {
            margin-top: var(--fs-3);
          
            &-title {
                margin-bottom: 5px;
            }
        }

        &-btn {
            width: 100%;
            margin-top: 15px;
        }
    }

    &__icon {
        font-size: 1.2rem;
        height: 1em;
        vertical-align: -0.125em;
        color: #525d6c;
       
        cursor: pointer;
    }
}

.checkbox {
    margin-top: 14px;
    padding: 0 !important;
    font-size: 12px !important;
}

.link {
    font-weight: 700;
    color: var(--orange);
}

.exclamation_mark {
    width: 22px;
    color: var(--orange);
    cursor: pointer;
}



@media (max-width: 768px) {
    .exclamation_mark {
        display: none;
    }
}

