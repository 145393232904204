.profile-header {
    background-color: #f9f7f8;
    position: relative;
    z-index: 10;
    &::after {
        content: '';
        top: 0;
        width: 100%;
        min-height: 70px;
        box-shadow: 0px 8px 25px 0px rgba(86, 99, 133, 0.15);
        position: absolute;
    }

    &__container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1210px;
        padding: 20px;
        margin: auto;
        z-index: 10;
    }

    &__logo {
        filter: brightness(0) saturate(100%) invert(16%) sepia(62%) saturate(5890%)
            hue-rotate(240deg) brightness(78%) contrast(95%);
    }
}
