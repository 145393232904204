.footer {
    &__policy {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-between;
        width: 100%;
        color: var(--text-gray);
        &-link {
            &:hover {
                color: #fff;
            }
        }
    }
}
