.contest-preview {
    padding: 20px;
    height: 100%;
    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--orange);
    cursor: pointer;
    &--inactive {
        filter: grayscale(1);
    }
    &__container {
        height: 100%;
        height: 280px;
        border-radius: 1rem;
        overflow: hidden;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
    }

    &__rubrics {
        text-align: center;
        background-color: var(--light-orange);
        width: fit-content;
        padding: 3px 20px;
        border-radius: 15px;
        color: #ffffff;
    }

    &__title {
        color: #fff;
        font-size: var(--fs-3);
        text-align: left;
        text-transform: uppercase;
        font-family: 'Gilroy-Medium';
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__result-status {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    &__result-btn {
        font-size: var(--fs-5);
        padding: 0.75rem 2rem;
        display: block;
        transition: 0.1s;
        border-radius: 1.4rem;
        color: var(--text-dark);
        border: 1px solid transparent;
        background: #f59b7e;
        font-weight: 500;
        color: #fff;
    }

    &__time {
        color: #fff;
    }

    &__prize {
        margin: 10px 0;
        &_title {
            color: #fff;
            font-size: var(--fs-4);
            text-align: left;
            font-family: 'Gilroy-Medium';
            font-weight: 700;
            margin-bottom: 12px;
        }
        &_description {
            font-size: var(--fs-5);
            color: #fff;
            white-space: break-spaces;
        }
    }
}
