.alert {

    &__modal {  
        height: 120px;
        width: min(240px, 65vw);
        padding: 7px;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        &-title {
            font-weight: 600;
            text-align: center;
        }

        &-btns-container {
            display: flex;
            gap: 10px;
            justify-content: space-between;
        }
    }
}
