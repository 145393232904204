.footer {
    &__logo-container {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__logo1 {
        width: 40px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(4340%)
            hue-rotate(322deg) brightness(122%) contrast(100%);
    }

    &__logo2 {
        width: 100px;
    }

    &__logo3 {
        width: 90px;
    }

    &__logo4 {
        width: 112px;
    }
}

@media (max-width: 576px) {
    .footer {
        &__logo-container {
            gap: 10px;
        }

        &__logo1 {
            width: 35px;
        }
        &__logo4 {
            width: 95px;
        }
    }
}
