.reset-pass {
    width: min(300px, 80vw);
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__form {
        &-title {
            font-size: var(--fs-3);
            margin-bottom: var(--fs-3);
        }

        &-input-pass {
            margin-top: var(--fs-4);
        }

        &-btn {
            margin-top: var(--fs-2);
            width: 100%;
        }

        &-input-title{
            margin-bottom: 5px;
        }
    }
}
