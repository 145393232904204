.search {
    &__list {
        display: none;
        width: 200px;

        max-height: 200px;
        position: absolute;

        border-radius: 0.5rem;
        z-index: 1000;
        overflow-y: auto;
        box-shadow:
            0 10px 22px rgba(0, 0, 0, 0.15),
            0 6px 6px rgba(0, 0, 0, 0.1);

        background-color: #fff;
        &::-webkit-scrollbar {
            width: 0;
        }
        &-item {
            padding: 5px;
            font-size: var(--fs-5);
            color: #525d6c;
            cursor: pointer;

            &--choosen {
                background-color: var(--light-orange);
                color: #fff;
            }

            &:hover {
                background-color: #eaeef0;
            }
        }

        &--closed {
            visibility: hidden;
        }
    }
}


.visible {
    display: block;
}